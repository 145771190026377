<template>
    <div class="home">
        <div class="chart_row">
            <div class="co1">
                <overview></overview>
            </div>
            <div class="co2">
                <todayMils></todayMils>
            </div>
        </div>
        <div class="chart_row">
            <div class="co1">
                <operation-status></operation-status>
            </div>
            <div class="co2">
                <execTimes></execTimes>
            </div>
        </div>
        <div class="chart_row">
            <div class="co3">
                <class-trend ></class-trend>
            </div>
            <div class="co4">
                <speed-trend></speed-trend>
            </div>
        </div>
        <div class="chart_row">
            <div class="co3">
                <mil-trend ></mil-trend>
            </div>
            <div class="co4">
                <runtime-trend></runtime-trend>
            </div>
        </div>
    </div>
</template>

<script>
    import Overview from "./Overview.vue";
    import OperationStatus from "./OperationStatus.vue";
    import ClassTrend from "./ClassTrend.vue";
    import SpeedTrend from "./SpeedTrend.vue";
    import MilTrend from "./MilTrend.vue";
    import RuntimeTrend from "./RuntimeTrend.vue";
    import TodayMils from "./TodayMils.vue";
    import execTimes from "./execTimes.vue"
    export default {
        components: {
            Overview,
            OperationStatus,
            ClassTrend,
            SpeedTrend,
            RuntimeTrend,
            MilTrend,
            TodayMils,
            execTimes
        },
    };
</script>
<style type="text/css" scoped="home" lang="scss">
    $border: 1px solid $bordercolor;
    .home {
        width: 100%;
        height: auto;
        min-width: 1200px;
        margin-right: auto;
        margin-left: auto;
        .chart_row {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            .co3 {
                width: 50%;
                margin-right: 20px;
                border: 1px solid #dcdfe6;
                padding: 20px;
                border: $border;
                box-sizing:border-box;
            }
            .co4 {
                flex: 1;
                border: 1px solid #dcdfe6;
                padding: 20px;
                border: $border;
                box-sizing:border-box;
            }
            .co1 {
                width: 82%;
                // margin-right: 20px;
                // border: 1px solid #dcdfe6;
                // padding: 20px;
                // border: $border;
            }
            .co2 {
                flex: 1;
                border: 1px solid #dcdfe6;
                padding: 20px;
                border: $border;
                box-shadow: #dcdcdc 0px 0px 7px;
            }
        }
    }
</style>
