<template>
    <div class="speed-wrapper">
        <h3>今日里程走势</h3>
        <div ref="mil" class="speed-chart"></div>
    </div>
</template>

<script>
    import * as Echarts from "echarts";
    export default {
        data() {
            return {
                legends: [],
                times:["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00","07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00",
                        "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00","21:00", "22:00", "23:00"],
                series:[]
            }
        },
        mounted() {
            console.log(this.$store.state.hourSpeed);
            this.getHourSpeed(this.$store.state.hourSpeed)
        },
        watch: {
            "$store.state.hourSpeed"(val) {
                this.getHourSpeed(val)
            }
        },
        methods: {
            getHourSpeed(value) {
                this.legends = []
                this.series = []
                if(value != null) {
                    value.forEach(item => {
                        let routeShift = []
                        let shiftTimes = item.mils;
                        this.legends.push(item.routeNa)
                        let keys = Object.keys(item.mils);
                        keys.sort();
                        for(let i = 0; i < this.times.length; i++) {
                            if(this.times[i] < keys[keys.length-1]) {
                                routeShift.push(0)
                            } else {
                                routeShift.push("")
                            }
                        }
                        keys.forEach(item=>{
                            for(let j = 0; j < this.times.length; j++) {
                                if(item == this.times[j]) {
                                    routeShift[j] = shiftTimes[item]
                                }
                            }
                        })
                        let serie = {
                            name: item.routeNa,
                            type: "line",
                            data: routeShift
                        }
                        this.series.push(serie)
                    });
                    this.draw();
                }
            },
            draw() {
                const dom = this.$refs.mil;
                const eIns = Echarts.init(dom,'light');
                const option = {
                    tooltip: {
                        trigger: "axis"
                    },
                    legend: {
                        right: 0,
                        top: "middle",
                        orient: "vertical",
                        data: this.legends
                    },
                    grid: {
                        left: "3%",
                        right: "22%",
                        bottom: 0,
                        top: "7%",
                        containLabel: true
                    },
                    xAxis: {
                        type: "category",
                        boundaryGap: ["10%", "10%"], // 根据legend长度计算
                        axisLabel: {
                            rotate: 90,
                            interval: 0
                        },
                        data: this.times
                    },
                    yAxis: {
                        type: "value",
                        axisLine: {
                            show: true
                        },
                        axisTick: {
                            show: true
                        }
                    },
                    series: this.series
                };
                eIns.clear();
                eIns.setOption(option);
            }
        }
    };
</script>
<style lang="scss" scoped="speed-wrapper">
    .speed-wrapper {
        height: 298px;
        width: auto;
        display: flex;
        flex-direction: column;
        .speed-chart {
            flex: 1;
        }
    }
</style>
