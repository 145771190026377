<template>
    <div class="info-wrapper">
        <div>
            <p>
                <el-divider content-position="left">
                    <img :src="require('@/assets/home/png/times.png')" class="img"/>
                    <span class="title">执行趟数</span>
                </el-divider>
            </p>
        </div>
        <div class="info"><span>{{executeTimes}}</span>趟次</div>
    </div>
</template>
<script>
 export default {
    data(){
        return{
            executeTimes: ''
        }
    },
    mounted() {
        this.getTodayMil()
    },
    methods: {
        getTodayMil() {
            let url = "/home/basicView"
            this.$http.post(url).then(({ detail }) => {
                    this.executeTimes = detail.executeTimes
            });
        },
    }
}
</script>
<style lang="scss">
    .el-divider--horizontal {
        margin: 10px 0;
    }
    .info-wrapper {
        display: flex;
        flex-direction: column;
        .img {
            height: 20px;
            padding-right: 5px;
        }
        .title {
            font-size: 16px;
            font-weight: bold;
        }
        .info {
            text-align: right;
            font-size: 20px;
            font-weight: bold;
            margin-top: 15px;
        }
    }
</style>