<template>
    <div class="info-wrapper">
        <div class="jy-homeSum">
            <div class="homelayout">
                <div class="homeitem" v-for="(one) in OperationList" v-bind:key="one.id" >
                    <slot :data="one">
                        <div :id="one.name" class="img"></div>
                    </slot>
                    <div class="text">
                        <div class="number">{{one.num}}</div>
                        <div>{{one.info}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import * as Echarts from "echarts";
    export default {
        data() {
            return {
                intervalObj: null,
                OperationList:[
                    {
						info: "趟次完成率",
						num: "",
                        canClick: false,
                        name:"shiftRate"
					},
                    {
						info: "发车准点率",
						num: "",
                        canClick: false,
                        name:"dePunctualityRate"
					},{
						info: "早高峰完成率",
						num: "",
                        canClick: false,
                        name:"mPeakCompletionRate"
					},{
						info: "晚高峰完成率",
						num: "",
                        canClick: false,
                        name:"nPeakCompletionRate"
					},{
						info: "早高峰准点率",
						num: "",
                        canClick: false,
                        name:"mPeakPunctualityRate"
					},{
						info: "晚高峰准点率",
						num: "",
                        canClick: false,
                        name:"nPeakPunctualityRate"
					},{
						info: "首班准点率",
						num: "",
                        canClick: false,
                        name:"firstShiftCompletionRate"
					},{
						info: "末班准点率",
						num: "",
                        canClick: false,
                        name:"endShiftCompletionRate"
					}
                ]
            }
        },
        mounted() {
            this.getShiftCompletionRate();
            this.getDePunctualityRate();
            this.getMAndnPeakRate();
            this.getMAndnPunctualityRate();
            this.getFirstShiftCompletionRate();
            this.getEndShiftCompletionRate();
        },
        methods: {
            getoption(val) {
                let Option = {
                    series: [
                        {
                            name: '完成率',
                            type: 'pie',
                            selectedMode: 'single',
                            radius: ['40%', '90%'],
                            labelLine: {
                                show: false
                            },
                            data: [
                                {value: val, name: '完成'+val+'%'},
                                {value: 100-val, name: '未完成'+(100-val)+'%'},
                            ],
                            color: ['#169bd5','#cccccc']
                        }
                    ],
                    graphic: {
                        elements: [
                            {
                                type: 'text',
                                left: 'center',
                                top: '45%',
                                style: {
                                    text: val+'%',
                                    fontSize: 10,
                                    textAlign: 'center',
                                    width: 30,
                                    height: 25,
                                    fill: '#3BA5D9'
                                }
                        }]
                    }
                }
                return Option
            },
            getShiftCompletionRate() {
                let url = "/home/shiftCompletionRate"
                this.$http.post(url).then(({ detail }) => {
                    this.OperationList.forEach(item=>{
                        if(item.name == 'shiftRate') {
                            item.num = Math.round(detail*100)+'%'
                        }
                    })
                    let shift = Math.round(detail*100)
                    let Option = this.getoption(shift)
                    this.draw("shiftRate",Option)
                });
            },
            getDePunctualityRate() {
                let url = "/home/dePunctualityRate"
                this.$http.post(url).then(({ detail }) => {
                    this.OperationList.forEach(item=>{
                        if(item.name == 'dePunctualityRate') {
                            item.num = Math.round(detail*100)+'%'
                        }
                    })
                    let dePunctualityRate = Math.round(detail*100)
                    let Option = this.getoption(dePunctualityRate)
                    this.draw("dePunctualityRate",Option)
                });
            },
            getMAndnPeakRate() {
                let url = "/home/mAndnPeakCompletionRate"
                this.$http.post(url).then(({ detail }) => {
                    this.OperationList.forEach(item=>{
                        if(item.name == 'mPeakCompletionRate') {
                            item.num = Math.round(detail.mPeakCompletionRate)+'%'
                            let mPeakCompletionRate = Math.round(detail.mPeakCompletionRate)
                            let Option = this.getoption(mPeakCompletionRate)
                            this.draw("mPeakCompletionRate",Option)
                        }
                        if(item.name == 'nPeakCompletionRate') {
                            item.num = Math.round(detail.nPeakCompletionRate)+'%'
                            let nPeakCompletionRate = Math.round(detail.nPeakCompletionRate)
                            let Option = this.getoption(nPeakCompletionRate)
                            this.draw("nPeakCompletionRate",Option)
                        }
                    })
                });
            },
            getMAndnPunctualityRate() {
                let url = "/home/mAndnPeakPunctualityRate"
                this.$http.post(url).then(({ detail }) => {
                    this.OperationList.forEach(item=>{
                        if(item.name == 'mPeakPunctualityRate') {
                            item.num = Math.round(detail.mPeakPunctualityRate)+'%'
                            let mPeakPunctualityRate = Math.round(detail.mPeakPunctualityRate)
                            let Option = this.getoption(mPeakPunctualityRate)
                            this.draw("mPeakPunctualityRate",Option)
                        }
                        if(item.name == 'nPeakPunctualityRate') {
                            item.num = Math.round(detail.nPeakPunctualityRate)+'%'
                            let nPeakPunctualityRate = Math.round(detail.nPeakPunctualityRate)
                            let Option = this.getoption(nPeakPunctualityRate)
                            this.draw("nPeakPunctualityRate",Option)
                        }
                    })
                });
            },
            getFirstShiftCompletionRate() {
                let url = "/home/firstShiftCompletionRate"
                this.$http.post(url).then(({ detail }) => {
                    this.OperationList.forEach(item=>{
                        if(item.name == 'firstShiftCompletionRate') {
                            item.num = Math.round(detail*100)+'%'
                        }
                    })
                    let firstShiftCompletionRate = Math.round(detail*100)
                    let Option = this.getoption(firstShiftCompletionRate)
                    this.draw("firstShiftCompletionRate",Option)
                });
            },
            getEndShiftCompletionRate() {
                let url = "/home/endShiftCompletionRate"
                this.$http.post(url).then(({ detail }) => {
                    this.OperationList.forEach(item=>{
                        if(item.name == 'endShiftCompletionRate') {
                            item.num = Math.round(detail*100)+'%'
                        }
                    })
                    let endShiftCompletionRate = Math.round(detail*100)
                    let Option = this.getoption(endShiftCompletionRate)
                    this.draw("endShiftCompletionRate",Option)
                });
            },
            draw(name,Option) {
                let eIns = Echarts.init(document.getElementById(name));
                eIns.clear();
                eIns.setOption(Option,true);
            }
        }
    };
</script>
<style lang="scss" scoped="info-wrapper">
    .info-wrapper {
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        .jy-homeSum {
            box-shadow: #dcdcdc 0px 0px 7px;
            padding: 20px 20px;
            background: #fff;
            .homelayout {
                // display: flex;
                flex: 1;
                flex-wrap: wrap;
                justify-content: space-evenly;
                .homeitem {
                    // flex: 1;
                    display: inline-flex;
                    &.hover{
                        cursor: pointer;
                    }
                    .img{
                        height: 60px;
                        width: 60px;
                    }
                    .text {
                        padding: 10px;
                        display: flex;
                        flex-direction: column;
                        .number {
                            flex:1;
                            font-weight: bold;
                            font-size: 25px;
                            color:#5A5A5A;
                            + div {
                                color: #B3B8CE;
                                font-size: 16px;
                                font-weight: 600;
                            }
                        }
                    }
                    + .homeitem {
                        border-left: 1px solid #ebebeb;
                        padding-left: 20px;
                    }
                }
            }
        }
    }
</style>
